import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getSelfEnrollment } from "App/Admin/_graphql/queries/self_enrollment";
import {
  saveSelfEnrollment,
  publishSelfEnrollment,
} from "App/Admin/_graphql/mutations/self_enrollment";
import { useApolloClient } from "@apollo/client";
import {
  IconKeyboard,
  IconPalette,
  IconChevronLeft,
  IconEye,
  IconEyeOff,
} from "@tabler/icons-react";
import { Loading } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import Preview from "./_components/Preview/Preview";
import Flow from "./_components/Flow/Flow";
import Theme from "./_components/Theme/Theme";
import Personal from "./_components/Personal/Personal";
import Email from "./_components/Email/Email";
import Phone from "./_components/Phone/Phone";
import Location from "./_components/Location/Location";
import Screening from "./_components/Screening/Screening";
import PaymentScheduling from "./_components/PaymentScheduling/PaymentScheduling";
import CreditSelect from "./_components/CreditSelect/CreditSelect";
import Review from "./_components/Review/Review";
import PaymentInformation from "./_components/PaymentInformation/PaymentInformation";
import { pageMap } from "./_helpers";
import "./SelfEnrollment.scss";

function SelfEnrollment(props) {
  const { itemId } = useParams();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [originalSettings, setOriginalSettings] = useState(null);
  const [previewPage, setPreviewPage] = useState("");
  const [togglePreview, setTogglePreview] = useState(false);
  const [published, setPublished] = useState(false);

  const triggerPreview = () => {
    setTogglePreview(false);
    setTimeout(() => {
      setTogglePreview(true);
    }, 10);
  };

  // Load Payments
  const fetchSelfEnrollment = async () => {
    const queryData = await client.query({
      query: getSelfEnrollment,
    });
    setLoading(false);
    const seData = queryData?.data?.getSelfEnrollment;
    setPublished(seData?.status === "published");
    delete seData?.status;
    setSettings(seData || null);
    setOriginalSettings(JSON.stringify(seData));
    triggerPreview();
  };

  const updateSelfEnrollment = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          settings,
        },
        mutation: saveSelfEnrollment,
      });
      setTimeout(async () => {
        await fetchSelfEnrollment();
        setSaving(false);
        message.success(`Self-enrollment successfully saved`);
      }, 1000);
    } catch (err) {
      message.error(
        getErrors(err) || `Error saving self-enrollment, try again`
      );
      setSaving(false);
    }
  };

  const publishSelfEnrollmentDraft = async () => {
    setPublishing(true);
    try {
      await client.mutate({
        variables: {
          id: settings._id,
        },
        mutation: publishSelfEnrollment,
      });
      setTimeout(async () => {
        await fetchSelfEnrollment();
        setPublishing(false);
        message.success(`Self-enrollment successfully published`);
      }, 1000);
    } catch (err) {
      message.error(
        getErrors(err) || `Error publishing self-enrollment, try again`
      );
      setPublishing(false);
    }
  };

  const u = (f, v) => {
    const n = { ...settings };
    n[f] = v;
    setSettings(n);
  };

  const us = (p, f, v) => {
    const n = { ...settings };
    n.settings[p][f] = v;
    setSettings(n);
  };

  const isEdited = originalSettings !== JSON.stringify(settings);

  const pageValidation = {};
  settings?.order?.forEach((pageId, index) => {
    pageValidation[pageId] = true;

    // Get required pages for this page from pageMap
    const requiredPages = pageMap[pageId]?.required || [];

    // Check each required page is before this page
    requiredPages.forEach((requiredPageId) => {
      const requiredPageIndex = settings.order.findIndex(
        (id) => id === requiredPageId
      );

      // Invalid if required page doesn't exist or is after current page
      if (requiredPageIndex === -1 || requiredPageIndex >= index) {
        pageValidation[pageId] = false;
      }
    });
  });
  const allValid = Object.values(pageValidation).every((valid) => valid);

  const seProps = {
    settings,
    reload: fetchSelfEnrollment,
    u,
    us,
    page: previewPage,
    previewPage: previewPage,
    setPreviewPage: setPreviewPage,
    pageValidation,
  };

  const previewIndex =
    settings?.order?.findIndex((page) => page === previewPage) || 0;

  let page = previewIndex === 0 ? "" : previewPage;
  if (itemId) page = itemId;

  useEffect(() => {
    props.setCrumbs({
      title: itemId ? (
        <div className="se-title">
          <Link to="/settings/self-enrollment">
            <Button type="blanco" size="small" className="boxed">
              <IconChevronLeft />
            </Button>
          </Link>
          <div className="bread">
            <span>Self Enrollment</span>
            <span className="slash">/</span>
            <span>{pageMap[itemId]?.title || "Something"} Options</span>
          </div>
        </div>
      ) : (
        <>
          <IconKeyboard className="force" />
          Self Enrollment
        </>
      ),
      icon: null,
      description: itemId
        ? null
        : "Adjust settings for the self enrollment application.",
    });
    props?.setCurrentCount(0);
    fetchSelfEnrollment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(triggerPreview, [page]);

  const isItemVisible = settings?.settings?.[itemId]?.visible;

  return loading ? (
    <Loading />
  ) : (
    <div className="self-enrollment-component">
      <div className="settings-search">
        <div className="ms-actions">
          {!props.readOnly ? (
            <>
              <Button
                type="blanco"
                size="small"
                loading={saving}
                onClick={updateSelfEnrollment}
                disabled={!isEdited}
              >
                Save
              </Button>
              <Button
                type="paypath"
                size="small"
                loading={publishing}
                onClick={publishSelfEnrollmentDraft}
                disabled={published || isEdited || !allValid}
              >
                Publish
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <div className="se-left">
        <div className="se-container">
          {!itemId ? (
            <>
              <div className="se-theme">
                <IconPalette />
                <span>Theme & Settings</span>
                <Link to="/settings/self-enrollment/theme">
                  <Button type="blanco" size="small" className="boxed">
                    Edit
                  </Button>
                </Link>
              </div>
              <div className="se-divider"></div>
              <Flow {...seProps} />
            </>
          ) : (
            <>
              {!isItemVisible && itemId !== "theme" ? (
                <div className="se-banner">
                  <div className="se-banner-icon">
                    <IconEyeOff />
                  </div>
                  <div className="se-banner-title">
                    This page is <b>Disabled</b>. Applicants are unable to see
                    it.
                  </div>
                  <Button
                    type="dark"
                    size="small"
                    className="boxed"
                    onClick={() => {
                      us(itemId, "visible", true);
                    }}
                  >
                    <IconEye />
                    Enable
                  </Button>
                </div>
              ) : null}
              {itemId === "theme" ? <Theme {...seProps} /> : null}
              {itemId === "personal" ? <Personal {...seProps} /> : null}
              {itemId === "email" ? <Email {...seProps} /> : null}
              {itemId === "phone" ? <Phone {...seProps} /> : null}
              {itemId === "location" ? <Location {...seProps} /> : null}
              {itemId === "screening" ? <Screening {...seProps} /> : null}
              {itemId === "payment_scheduling" ? (
                <PaymentScheduling {...seProps} />
              ) : null}
              {itemId === "credit_select" ? (
                <CreditSelect {...seProps} />
              ) : null}
              {itemId === "review" ? <Review {...seProps} /> : null}
              {itemId === "payment_info" ? (
                <PaymentInformation {...seProps} />
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className="se-right">
        {togglePreview ? <Preview settings={settings} page={page} /> : null}
      </div>
    </div>
  );
}

export default SelfEnrollment;
