import { gql } from "@apollo/client";

export const getImports = gql`
  query {
    getImports {
      id
      category
      original_file_url
      failures_file_url
      total_records
      successful_records
      failed_records
      result
      user {
        id
        name
        company {
          id
          name
          logo
        }
      }
      createdAt
      updatedAt
    }
  }
`;
