import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconForms, IconPlus } from "@tabler/icons-react";
import { FormInput, InputLabel, Select, Flex, Loading } from "_styleguide";
import { saveCustomField } from "App/Admin/_graphql/mutations/custom_field";
import { getCustomField } from "App/Admin/_graphql/queries/custom_field";
import { fieldTypes } from "./_helpers";
import "./ManageCustomField.scss";

function ManageCustomField(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [field, setField] = useState();

  const isEditing = props.fieldId !== "new";

  const fetchField = async () => {
    if (isEditing) {
      const queryData = await client.query({
        variables: { custom_field_id: props?.fieldId },
        query: getCustomField,
      });
      setLoading(false);
      setField(queryData?.data?.getCustomField);
    } else {
      setLoading(false);
      setField({
        name: undefined,
        type: undefined,
        description: undefined,
        options: [],
      });
    }
  };

  // Submit form
  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          custom_field_id: props.fieldId !== "new" ? props.fieldId : null,
          ...field,
        },
        mutation: saveCustomField,
      });
      message.success(
        `Custom Field successfully ${isEditing ? "saved" : "created"}`
      );
      props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) ||
          `Error ${isEditing ? "saving" : "creating"} custom field, try again`
      );
      setSaving(false);
    }
  };

  const updateForm = (fieldName, value) => {
    const editedForm = { ...field };
    editedForm[fieldName] = value;
    setField(editedForm);
  };

  const updateOptions = (index, fieldName, value) => {
    const editedForm = { ...field };
    editedForm.options[index][fieldName] = value;
    setField(editedForm);
  };

  useEffect(() => {
    fetchField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = field?.name?.length > 0 && field?.type?.length > 0;

  return (
    <Modal
      className="manage-custom-field-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={loading ? true : false}
      width={500}
    >
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <>
          <div className="pp-modal-header">
            <div>
              <IconForms />
            </div>
            <h3>{isEditing ? "Edit" : "Create"} CustomField</h3>
          </div>

          <Modal.Body>
            <Flex vertical={true}>
              <InputLabel required>Field Name</InputLabel>
              <FormInput
                placeholder="e.g. Total Employees"
                value={field?.name}
                onChange={(e) => updateForm("name", e.target.value)}
                size="small"
              />
            </Flex>

            <Flex vertical={true}>
              <InputLabel required>Field Type</InputLabel>
              <Select
                value={field?.type}
                onChange={(val) => updateForm("type", val)}
                popupClassName="pp-select-dropdown mcf-select-dropdown"
                style={{ width: "100%" }}
                size="medium"
                placeholder="---"
              >
                {Object.keys(fieldTypes).map((type) => {
                  const fieldType = fieldTypes[type];
                  return (
                    <Select.Option
                      key={`type-${fieldType.value}`}
                      value={fieldType.value}
                    >
                      <div className="mcf-select">
                        {fieldType.icon}
                        <div className="mcf-select-label">
                          <h5>{fieldType.label}</h5>
                          <p>{fieldType.description}</p>
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Flex>

            <Flex vertical={true}>
              <InputLabel required>Description</InputLabel>
              <FormInput
                placeholder="e.g. Total number of employees in the company"
                value={field?.description}
                onChange={(e) => updateForm("description", e.target.value)}
              />
            </Flex>
            {field?.type === "Dropdown" && (
              <>
                <div className="mcf-divider" />
                <Flex vertical={true} className="mcf-dropdown-options">
                  <InputLabel required>Dropdown Options</InputLabel>
                  <Button
                    type="secondary"
                    size="tiny"
                    onClick={() =>
                      updateForm("options", [
                        ...field?.options,
                        { label: undefined, value: undefined },
                      ])
                    }
                  >
                    <IconPlus /> Option
                  </Button>
                  {field?.options?.length <= 0 ? (
                    <p>No options added yet</p>
                  ) : (
                    field?.options?.map((option, index) => (
                      <Flex
                        gap="12px"
                        align="center"
                        key={`mcf-option-${index}`}
                      >
                        <FormInput
                          placeholder={`Option #${index + 1} - Label`}
                          value={option.label}
                          size="small"
                          onChange={(e) =>
                            updateOptions(index, "label", e.target.value)
                          }
                        />
                        <FormInput
                          placeholder={`Option #${index + 1} - Value`}
                          value={option.value}
                          size="small"
                          onChange={(e) =>
                            updateOptions(index, "value", e.target.value)
                          }
                        />
                      </Flex>
                    ))
                  )}
                </Flex>
              </>
            )}
          </Modal.Body>
          <Modal.Actions>
            <Button
              disabled={saving}
              block
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveForm}
              loading={saving}
              block
              type="primary"
              disabled={!isValid}
            >
              {isEditing ? "Save" : "Create"} Field
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default ManageCustomField;
