import { useState } from "react";
import { createDefaultMapping, mapping_to_table } from "../../_helpers/upload";
import { IconLine } from "@tabler/icons-react";
import { Select } from "_styleguide";
import { Button, Modal } from "@centrate-io/barn";
import { submitMapping } from "../../_helpers/mapping";
import { expectedColumns } from "../../_constants/columns";
import { MappingAction, FieldMapping } from "./_helpers";
import "./Mapping.scss";

function Mapping(props) {
  const columns = expectedColumns[props.mappingType];
  const requiredColumns = expectedColumns[`${props.mappingType}_required`];
  const [mapping, setMapping] = useState(
    createDefaultMapping(props.data, columns, props.dbMapping)
  );
  const cols = columns;
  const changeRow = (index, value) => {
    let newMapping = [...mapping];
    newMapping[index].col = value;
    newMapping[index].isMapped = true;
    setMapping(newMapping);
  };

  const changeMapping = (index, value) => {
    let newMapping = [...mapping];
    newMapping[index].mappingAction = value;
    newMapping[index].col = null;
    newMapping[index].isMapped = false;
    setMapping(newMapping);
  };

  const taken = new Set(
    mapping.filter((m) => m.col !== null).map((m) => m.col) || []
  );

  const uploadLoans = async () => {
    Modal.confirm({
      title: "Are you sure you want import this Data?",
      okText: "Import Data",
      content:
        "Be sure to double check and make sure your columns are mapped properly before uploading",
      onOk: async () => {
        const actualData = mapping_to_table(mapping, props.data);
        // If mapping is setup, submit latest to database
        if (props.mappingType) {
          await submitMapping(mapping, props.mappingType);
        }
        await props.apiFunction(actualData);
      },
    });
  };

  return (
    <div className="import-mapping">
      <Modal.Close onClick={() => props.setVisible(false)} />

      <div className="pp-modal-header">
        <div>
          <IconLine />
        </div>
        <h3> Map Sheet Columns</h3>
      </div>
      <div className="wb-headers">
        <div>Imported from CSV/Excel</div>
        <div>Action</div>
        <div>Mapping</div>
      </div>

      <div className="wb-work">
        {mapping?.map((m, i) => (
          <div key={`wb-map-${i}`} className="wb-map">
            <div>
              <b>{m.csvColumn}</b>
            </div>
            <div>
              <MappingAction
                value={m.mappingAction}
                onChange={(val) => changeMapping(i, val)}
              />
            </div>
            <div>
              {m.mappingAction === "field" ? (
                <FieldMapping
                  cols={cols}
                  taken={taken}
                  value={m.col}
                  onChange={(val) => changeRow(i, val)}
                />
              ) : null}
              {m.mappingAction === "ignore" ? (
                <div className="ma-info">Ignoring this column...</div>
              ) : null}
              {m.mappingAction === "unmatched" ? (
                <div className="ma-info">Select an action...</div>
              ) : null}
              {m.mappingAction === "new_field" ? (
                <div className="ma-info">To do...</div>
              ) : null}
            </div>
            {/* <div>
              <i>{m.example || "n/a"}</i>
            </div> */}
          </div>
        ))}
      </div>
      <div className="wb-actions">
        <Button className="boxed" type="secondary" onClick={props.removeModal}>
          Cancel
        </Button>
        <Button className="boxed" type="primary" onClick={uploadLoans}>
          Import {props.data?.length} item(s)
        </Button>
      </div>
    </div>
  );
}

export default Mapping;
