import React, { useEffect, useState } from "react";
import IphoneIconsSVG from "_assets/images/iphone-icons.svg";
import { IconLockFilled } from "@tabler/icons-react";
import "./Preview.scss";

function Preview(props) {
  const { settings, page } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const iframe = document.querySelector(".preview-iframe");

    const handleIframeLoad = () => {
      if (iframe?.contentWindow && settings) {
        iframe.contentWindow.postMessage(
          {
            type: "PAYPATH_SETTINGS",
            settings: settings,
          },
          "*"
        );
      }
    };

    if (iframe && !loaded) {
      iframe.addEventListener("load", handleIframeLoad);
      setLoaded(true);
    } else if (iframe && loaded) {
      handleIframeLoad();
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [settings]);

  const iframeUrl = `https://apply-new.paypath.dev/${page}`;
  //const iframeUrl = `http://localhost:3001/${page}`;

  return (
    <div className="mobile-device-preview">
      <div className="mobile-device-frame">
        <div className="mobile-device-top">
          <div className="mobile-device-notch"></div>
          <div>9:41</div>
          <div>
            <img src={IphoneIconsSVG} alt="" />
          </div>
        </div>
        <div className="mobile-device-screen">
          <iframe
            title="Self Enrollment Preview"
            src={`${iframeUrl}?preview=paypath`}
            className="preview-iframe"
          />
        </div>
        <div className="mobile-device-bottom">
          <div className="bottom-notch"></div>
          <div>
            <IconLockFilled /> {iframeUrl}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
