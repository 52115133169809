import { gql } from "@apollo/client";

export const saveCustomField = gql`
  mutation saveCustomField(
    $custom_field_id: String
    $name: String!
    $type: String!
    $options: [CustomFieldOptionArgs]
    $description: String
    $category: String
  ) {
    saveCustomField(
      custom_field_id: $custom_field_id
      name: $name
      type: $type
      options: $options
      description: $description
      category: $category
    )
  }
`;

export const removeCustomField = gql`
  mutation removeCustomField($custom_field_id: String!) {
    removeCustomField(custom_field_id: $custom_field_id)
  }
`;

export const archiveCustomField = gql`
  mutation archiveCustomField($custom_field_id: String!, $archived: Boolean!) {
    archiveCustomField(custom_field_id: $custom_field_id, archived: $archived)
  }
`;

export const saveApplicationCustomFields = gql`
  mutation saveApplicationCustomFields(
    $application_id: String!
    $fields: [ApplicationCustomFieldInput!]!
  ) {
    saveApplicationCustomFields(
      application_id: $application_id
      fields: $fields
    )
  }
`;
