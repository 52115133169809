import { Text } from "@centrate-io/barn";
import {
  IconUser,
  IconCalendar,
  IconFileImport,
  IconFileSpreadsheet,
  IconCategory,
  IconCheck,
  IconX,
  IconPercentage,
  IconExternalLink,
  IconDownload,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import moment from "moment";

export const getColumns = () => {
  return [
    {
      title: (
        <div className="column-name">
          <IconCategory />
          Category
        </div>
      ),
      dataIndex: "category",
      key: "category",
      width: 180,
      render: (category, data) => (
        <div>
          <Text className="doc-name">
            <IconCategory />
            <b>{category}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            ID: <i>{data.id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconFileSpreadsheet />
          Import Stats
        </div>
      ),
      dataIndex: "total_records",
      key: "total_records",
      width: 250,
      render: (total, data) => (
        <div>
          <Text className="doc-name">
            <IconFileSpreadsheet />
            <b>{data.total_records} records</b>
          </Text>
          <div style={{ paddingLeft: "20px" }}>
            <Text>
              <IconCheck size={14} color="green" /> {data.successful_records}{" "}
              successful
              {data.failed_records > 0 && (
                <span style={{ marginLeft: "8px" }}>
                  <IconX size={14} color="red" /> {data.failed_records} failed
                </span>
              )}
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconFileImport />
          Result
        </div>
      ),
      dataIndex: "result",
      key: "result",
      width: 120,
      render: (result) => (
        <div>
          <Tag
            type={
              result === "success"
                ? "success"
                : result === "partial"
                ? "warning"
                : "error"
            }
            size="small"
          >
            {result}
          </Tag>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Imported By
        </div>
      ),
      dataIndex: "user",
      key: "user",
      width: 240,
      render: (user, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{user?.name || "Unknown User"}</b>
          </Text>
          {user?.company && (
            <Text className="helper" style={{ paddingLeft: "20px" }}>
              {user.company.name}
            </Text>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconExternalLink />
          Files
        </div>
      ),
      dataIndex: "original_file_url",
      key: "files",
      width: 200,
      render: (original_file_url, data) => (
        <div>
          {original_file_url && (
            <a
              href={original_file_url}
              target="_blank"
              rel="noopener noreferrer"
              className="doc-name"
              style={{ display: "block", marginBottom: "4px" }}
            >
              <IconDownload size={16} /> Original File
            </a>
          )}
          {data.failures_file_url && data.failed_records > 0 && (
            <a
              href={data.failures_file_url}
              target="_blank"
              rel="noopener noreferrer"
              className="doc-name"
            >
              <IconDownload size={16} /> Failed Records
            </a>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Created
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 180,
      render: (date) => (
        <Text>
          {moment(date).format("MMM D, YYYY")}
          <br />
          <span className="helper">{moment(date).format("h:mm A")}</span>
        </Text>
      ),
    },
  ];
};
