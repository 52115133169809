import { gql } from "@apollo/client";

export const inviteUser = gql`
  mutation inviteUser($email: String!, $role: String!) {
    inviteUser(email: $email, role: $role)
  }
`;

export const resendInviteUser = gql`
  mutation resendInviteUser($user_id: String!) {
    resendInviteUser(user_id: $user_id)
  }
`;

export const changeUserRole = gql`
  mutation changeUserRole($user_id: String!, $role: String!) {
    changeUserRole(user_id: $user_id, role: $role)
  }
`;

export const masqueradeUser = gql`
  mutation masqueradeUser($user_id: String!, $company_id: String!) {
    masqueradeUser(user_id: $user_id, company_id: $company_id)
  }
`;

export const masqueradeBack = gql`
  mutation masqueradeBack {
    masqueradeBack
  }
`;
