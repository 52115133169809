import { useState, useEffect } from "react";
import { Modal } from "@centrate-io/barn";
import Upload from "./_components/Upload/Upload";
import Mapping from "./_components/Mapping/Mapping";
import Workbook from "./_components/Workbook/Workbook";
import SelectType from "./_components/SelectType/SelectType";
import { getMappings } from "./_helpers/mapping";
import "./ImportModal.scss";

function ImportModal(props) {
  const [visible, setVisible] = useState(true);
  const [spreadsheet, setSpreadsheet] = useState();
  const [workbook, setWorkbook] = useState();
  const [dbMapping, setDbMapping] = useState();
  const [mappingType, setMappingType] = useState(
    props.mappingType || undefined
  );

  return (
    <Modal
      wrapClassName="import-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={(!spreadsheet && !workbook) || !mappingType ? true : false}
      width={spreadsheet ? 800 : 500}
    >
      {!mappingType ? (
        <SelectType
          setMappingType={setMappingType}
          mappingType={mappingType}
          setVisible={props.removeModal}
        />
      ) : (
        <>
          {!spreadsheet && !workbook && (
            <Upload
              setSpreadsheet={setSpreadsheet}
              setWorkbook={setWorkbook}
              setVisible={props.removeModal}
            />
          )}
          {!spreadsheet && workbook && (
            <Workbook
              workbook={workbook}
              setSpreadsheet={setSpreadsheet}
              removeModal={props.removeModal}
            />
          )}
          {spreadsheet && (
            <Mapping
              data={spreadsheet}
              dbMapping={dbMapping}
              setVisible={props.removeModal}
              columns={props.columns}
              apiFunction={props.apiFunction}
              mappingType={mappingType}
            />
          )}
        </>
      )}
    </Modal>
  );
}

export default ImportModal;
