import React from "react";
import { useParams } from "react-router-dom";
import Menu from "./_components/Menu/Menu";
import Manage from "./Manage/Manage";
import Users from "./Users/Users";
import Partners from "./Partners/Partners";
import Teams from "./Teams/Teams";
import Tags from "./Tags/Tags";
import Triggers from "./Triggers/Triggers";
import Integrations from "./Integrations/Integrations";
import EditWorkflow from "./Workflows/_components/EditWorkflow/EditWorkflow";
import Workflows from "./Workflows/Workflows";
import TaskTemplates from "./TaskTemplates/TaskTemplates";
import Webhooks from "./Webhooks/Webhooks";
import SelfEnrollment from "./SelfEnrollment/SelfEnrollment";
import CustomFields from "./CustomFields/CustomFields";
import Import from "./Import/Import";
import "./Settings.scss";

const DEFAULT_TAB = "manage";

function Settings(props) {
  const { tab, itemId } = useParams();

  const globalProps = {
    tab: tab || DEFAULT_TAB,
    ...props,
  };

  if (tab === "workflows" && itemId) {
    globalProps.tab = "edit-workflow";
  }

  const needScroll = ["integrations", "webhooks"];

  const isPartner = props.user?.parent_company;

  return (
    <div className={`admin-settings`}>
      <Menu {...globalProps} />
      <div
        className={`settings-body ${
          needScroll.includes(globalProps.tab) ? "need-scroll" : ""
        }`}
      >
        {globalProps.tab === "manage" && <Manage {...globalProps} />}
        {globalProps.tab === "users" && <Users {...globalProps} />}
        {globalProps.tab === "partners" && !isPartner && (
          <Partners {...globalProps} />
        )}
        {globalProps.tab === "tags" && <Tags {...globalProps} />}
        {globalProps.tab === "triggers" && <Triggers {...globalProps} />}
        {globalProps.tab === "teams" && <Teams {...globalProps} />}
        {globalProps.tab === "workflows" && <Workflows {...globalProps} />}
        {globalProps.tab === "task-templates" && (
          <TaskTemplates {...globalProps} />
        )}
        {globalProps.tab === "edit-workflow" && (
          <EditWorkflow {...globalProps} />
        )}
        {globalProps.tab === "integrations" && (
          <Integrations {...globalProps} />
        )}
        {globalProps.tab === "webhooks" && <Webhooks {...globalProps} />}
        {globalProps.tab === "self-enrollment" && (
          <SelfEnrollment {...globalProps} />
        )}
        {globalProps.tab === "custom-fields" && (
          <CustomFields {...globalProps} />
        )}
        {globalProps.tab === "import" && <Import {...globalProps} />}
      </div>
    </div>
  );
}

export default Settings;
