import React, { useState, useEffect } from "react";
import { Table, Search } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { adminPartners } from "App/Admin/_graphql/queries/company";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import InviteCompany from "./_modals/InviteCompany/InviteCompany";
import { resendInviteCompany } from "App/Admin/_graphql/mutations/company";
import { message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconBuilding, IconPlus } from "@tabler/icons-react";
import "./Partners.scss";

function Partners(props) {
  const client = useApolloClient();
  const [inviteModal, setInviteModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: adminPartners,
    });
    props?.setCurrentCount(queryData?.data?.adminPartners?.length || 0);
    setState({
      loading: false,
      data: queryData?.data?.adminPartners || [],
    });
  };

  const resendInvite = async (company_id) => {
    try {
      await client.mutate({
        variables: { company_id },
        mutation: resendInviteCompany,
      });
      message.success(`Invite has been resent`);
    } catch (err) {
      message.error(getErrors(err) || "Error resending invite, try again");
    }
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Partners",
      icon: <IconBuilding />,
      description: "Organize and track data across your affiliate partners",
    });
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (state?.data || []).filter((u) => {
    if ((search?.length || 0) <= 0) return true;
    const nameSearch = u.name?.toLowerCase();
    const idSearch = u.short_id?.toLowerCase();
    return nameSearch.includes(search) || idSearch.includes(search);
  });
  const columns = getColumns(
    props?.user?.company?.id,
    resendInvite,
    props.readOnly
  );

  return (
    <div className="admin-companies">
      <div className="settings-search">
        <div className="ms-actions">
          {props.canManageCompanies && !props.readOnly ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setInviteModal(true)}
            >
              <IconPlus />
              Invite Partner
            </Button>
          ) : null}
        </div>
        <div className="ms-filters">
          <Search
            placeholder={`Search ${state.data?.length || ""} companies...`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 304 }}
          />
        </div>
      </div>
      {inviteModal ? (
        <InviteCompany
          reload={fetchDocuments}
          removeModal={() => setInviteModal(false)}
        />
      ) : null}
      <Table
        columns={columns}
        loading={state.loading}
        pagination={state.pagination}
        rowKey={(record) => record.id}
        dataSource={tableData}
        showSorterTooltip={false}
        scroll={{
          y: "calc(80vh - 231px)",
          x: "max-content",
        }}
        locale={{
          emptyText: state.loading
            ? "Loading companies..."
            : "No companies found",
        }}
      />
    </div>
  );
}

export default Partners;
