import { Select } from "_styleguide";
import {
  IconCheck,
  IconForms,
  IconPlus,
  IconAlertCircle,
  IconBan,
} from "@tabler/icons-react";

export const MappingAction = (props) => {
  return (
    <Select
      value={props.value}
      onChange={(val) => props.onChange(val)}
      size="medium"
      placeholder="Ignore"
      popupClassName="pp-select-dropdown ma-option-dropdown"
    >
      <Select.Option value="ignore">
        <div className="ma-option">
          <div className="ma-icon-ignore">
            <IconBan />
          </div>
          Ignore
        </div>
      </Select.Option>
      <Select.Option value="field">
        <div className="ma-option">
          <div className="ma-icon-field">
            <IconCheck />
          </div>
          Map to field
        </div>
      </Select.Option>
      <Select.Option value="new_field">
        <div className="ma-option">
          <div className="ma-icon-new">
            <IconPlus />
          </div>
          New field
        </div>
      </Select.Option>
      <Select.Option value="unmatched">
        <div className="ma-option">
          <div className="ma-icon-unmatched">
            <IconAlertCircle />
          </div>
          Unmatched
        </div>
      </Select.Option>
    </Select>
  );
};

export const FieldMapping = (props) => {
  return (
    <Select
      showSearch
      allowClear={true}
      value={props.value}
      onChange={(val) => props.onChange(val)}
      size="medium"
      placeholder="Select field"
      popupClassName="pp-select-dropdown ppsd-task ma-field-mapping"
    >
      {props.cols?.map((u, j) => (
        <Select.Option disabled={props.taken.has(u)} key={`lc-${j}`} value={u}>
          {u}
        </Select.Option>
      ))}
    </Select>
  );
};
