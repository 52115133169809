const leadColumns = [
  "first_name",
  "last_name",
  //   "co_first_name",
  "co_last_name",
  "address",
  "address_line_two",
  "city",
  "state",
  "zip",
  "dob",
  "ssn",
  "phone",
  "mobile",
  "email",
  "payment_method",
  "routing",
  "account",
  "bank_name",
  "credit_card",
  "expiration",
  "cvv",
  "date_closed",
  "agent",
  "status",
  "half_pay",
  "total_debt",
  "monthly_payment",
  "payments_made",
  "plan_term",
  "plan_payment",
  "plan_bi_weekly_payment",
  "plan_start_date",
];

const leadColumnsRequired = ["first_name", "last_name", "co_first_name"];

export const expectedColumns = {
  lead: leadColumns,
  lead_required: leadColumnsRequired,
};
