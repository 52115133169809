import React, { useState, useEffect } from "react";
import { Table } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { getImports } from "App/Admin/_graphql/queries/import";
import { getColumns } from "./_data/Columns";
import { Button } from "@centrate-io/barn";
import { IconTableImport, IconPlus } from "@tabler/icons-react";
import ImportModal from "./_modals/ImportModal/ImportModal";
import "./Import.scss";

function Import(props) {
  const client = useApolloClient();
  const [importModal, setImportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imports, setImports] = useState([]);
  const columns = getColumns();

  // Load Imports
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const queryData = await client.query({
        query: getImports,
        fetchPolicy: "network-only",
      });
      setImports(queryData?.data?.getImports || []);
      props?.setCurrentCount(queryData?.data?.getImports?.length || 0);
    } catch (error) {
      console.error("Error fetching imports:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Import Data",
      icon: <IconTableImport />,
      description: "Import data into PayPath from a CSV file",
    });
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh imports when modal closes
  const handleImportModalClose = () => {
    setImportModal(false);
    fetchDocuments();
  };

  return (
    <div className="settings-imports">
      {importModal && (
        <ImportModal
          removeModal={handleImportModalClose}
        />
      )}
      <div className="settings-search">
        <div className="ms-actions">
          {!props.readOnly ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setImportModal(true)}
            >
              <IconPlus />
              New Import
            </Button>
          ) : null}
        </div>
      </div>
      <Table
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10, showSizeChanger: false }}
        rowKey={(record) => record.id}
        dataSource={imports || []}
        showSorterTooltip={false}
        scroll={{
          y: "calc(80vh - 231px)",
          x: "max-content",
        }}
        locale={{
          emptyText: loading ? "Loading imports..." : "No imports found",
        }}
      />
    </div>
  );
}

export default Import;
