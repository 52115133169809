import {
  IconHash,
  IconLetterCase,
  IconAlignLeft,
  IconSelect,
} from "@tabler/icons-react";

export const fieldTypes = {
  ShortText: {
    value: "ShortText",
    label: "Short text",
    description: "For single line information",
    icon: <IconLetterCase />,
  },
  LongText: {
    value: "LongText",
    label: "Long text",
    description: "For long form/multi-line information",
    icon: <IconAlignLeft />,
  },
  Number: {
    value: "Number",
    label: "Number",
    description: "Use for any number based information",
    icon: <IconHash />,
  },
  Dropdown: {
    value: "Dropdown",
    label: "Dropdown",
    description: "Use for any dropdown based information",
    icon: <IconSelect />,
  },
};
