import axios from "_helpers/axios";

export const submitMapping = async (mapping, mappingType) => {
  const onlyMapped = mapping
    .filter((m) => m.isMapped)
    .map((m) => ({
      data_type: mappingType,
      sheet_column: m.csvColumn,
      table_column: m.col,
    }));
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/mappings/add`,
      onlyMapped,
    );
  } catch (error) {
    console.error(error, "Failed to import loans");
  }
};

export const getMappings = async (mappingType) => {
  let storedMappings = [];
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/mappings`,
      {
        params: {
          data_type: mappingType,
        },
      },
    );
    storedMappings = response.data;
    console.log(response.data);
  } catch (error) {
    console.error("Failed to fetch loans");
  }
  return storedMappings;
};
