import { Text } from "@centrate-io/barn";
import {
  IconUsers,
  IconUser,
  IconBuilding,
  IconCalendar,
  IconMail,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import moment from "moment";
import { TableActions } from "_styleguide";

export const getColumns = (myCompanyId, resendInviteCompany, readOnly) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconBuilding />
          Partner Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconBuilding />
            <b>{name}</b>
            {data?.role === "company-invite" && (
              <Tag type="success" size="small">
                Invite pending
              </Tag>
            )}
            {data?.role === "company" && data?.id !== myCompanyId && (
              <Tag type="neutral" size="small">
                Partner
              </Tag>
            )}

            {data?.role === "company" && data?.id === myCompanyId && (
              <Tag type="primary" size="small">
                You
              </Tag>
            )}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Owner
        </div>
      ),
      dataIndex: "user",
      key: "user",
      render: (user, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{data?.user?.name || "New User"}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.user?.email}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconUsers />
          Users
        </div>
      ),
      dataIndex: "user_count",
      key: "user_count",
      width: 120,
      render: (user_count) => (
        <Text>
          {user_count} user{user_count === 1 ? "" : "s"}
        </Text>
      ),
    },
    // {
    //   title: (
    //     <div className="column-name">
    //       <IconReceipt2 />
    //       Customers
    //     </div>
    //   ),
    //   dataIndex: "subscription_count",
    //   key: "subscription_count",
    //   width: 160,
    //   render: (subscription_count) => (
    //     <Text>
    //       {subscription_count} subscription{subscription_count === 1 ? "" : "s"}
    //     </Text>
    //   ),
    // },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Joined/Invited
        </div>
      ),
      dataIndex: "joined_date",
      key: "joined_date",
      render: (joined_date, data) => (
        <Text>
          {data.joined_date
            ? moment(parseInt(data.joined_date)).format("M/D/YYYY")
            : moment(parseInt(data.invited_date)).format("M/D/YYYY")}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      sorter: false,
      width: 60,
      fixed: "right",
      hidden: readOnly,
      render: (actions, data) => {
        const items = [];
        if (data.role === "company-invite") {
          items.push({
            label: (
              <span>
                <IconMail /> Resend Invite
              </span>
            ),
            onClick: () => resendInviteCompany(data.id),
          });
        }
        return items.length > 0 ? (
          <TableActions items={items} />
        ) : (
          <TableActions items={items} disabled />
        );
      },
    },
  ];
};
