import { IconTableImport, IconCircleCheckFilled } from "@tabler/icons-react";
import { Tag } from "_styleguide";
import { Modal } from "@centrate-io/barn";
import "./SelectType.scss";

function SelectType(props) {
  const MappingType = ({ typeName, name, description }) => (
    <div
      onClick={() => props.setMappingType(typeName)}
      className={`mp-option${
        typeName === props.mappingType ? " selected" : ""
      }`}
    >
      <Tag type={typeName} size="role">
        {name}
      </Tag>
      <p>{description}</p>
      <IconCircleCheckFilled />
    </div>
  );

  return (
    <>
      <Modal.Close onClick={() => props.setVisible(false)} />

      <div className="pp-modal-header">
        <div>
          <IconTableImport />
        </div>
        <h3> Where do you want to import data to?</h3>
      </div>

      <div className="mp-options">
        <MappingType
          typeName="lead"
          name="Lead/Customer"
          description="Import data to create a new lead or customer."
        />
        <MappingType
          typeName="payment"
          name="Payment"
          description="Import data to create a new payment."
        />
        <MappingType
          typeName="address"
          name="Address Book"
          description="Import data to create a new address book."
        />
      </div>
    </>
  );
}

export default SelectType;
