import { IconUpload } from "@tabler/icons-react";
import { Upload as AntDUpload } from "antd";
import { handleFileUpload } from "../../_helpers/upload";
import { Modal } from "@centrate-io/barn";
import "./Upload.scss";

const { Dragger } = AntDUpload;

function Upload(props) {
  return (
    <>
      <Modal.Close onClick={() => props.setVisible(false)} />

      <div className="pp-modal-header">
        <div>
          <IconUpload />
        </div>
        <h3> Upload Data</h3>
      </div>

      <div className="upload-doc">
        <Dragger
          name="file"
          multiple={false}
          beforeUpload={(file) =>
            handleFileUpload(file, props.setSpreadsheet, props.setWorkbook)
          }
          accept=".csv, .xls, .xlsx"
        >
          <div className="ud-drag-container">
            <div className="ud-drag-icon">
              <IconUpload />
            </div>
            <h4>Select .csv, .xls, or .xlsx to upload</h4>
            <p>Click here or drag and drop file</p>
          </div>
        </Dragger>
      </div>
    </>
  );
}

export default Upload;
